import axios from 'axios';
import AuthService from './AuthService';

class ApiService {
    /**
     * @returns {axios.AxiosInstance}
     */
    request = () => axios.create({
        baseURL: 'https://us-central1-todo-daf96.cloudfunctions.net/todoApi',
        /* timeout: 1000, */
        headers: { 'Authorization': 'Bearer ' + AuthService.authState().token }
    });
}

export default new ApiService();
