import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class AboutDialog extends React.Component {
  state = {
    open: false,
  };

  prepareDialog = () => {
      this.setState({
          open: this.props.open,
      });
  };

  componentDidMount = () => {
      this.prepareDialog();
  };

  componentDidUpdate = (prevProps) => {
      if (this.props.open !== prevProps.open) {
          this.prepareDialog();
      }
  };

  render() {
    return (
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.props.onCloseClick}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"About Beanlist"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                <p>
              Beanlist is a helpful to-do app.<br/>
              Copyright &copy; 2019 Gatehill Software.
                </p>
                <p>
                <a href="https://www.gatehill.io" target="_blank" rel="noopener noreferrer">https://www.gatehill.io</a>
                </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onCloseClick} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

AboutDialog.propTypes = {
    onCloseClick: PropTypes.func.isRequired,
};

export default AboutDialog;
