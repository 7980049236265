import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InfoIcon from '@material-ui/icons/Info';
import RefreshIcon from '@material-ui/icons/Refresh';
import AuthService from '../services/AuthService'

const styles = {
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
};

class NavDrawer extends React.Component {
    state = {
        open: false,
        signedIn: false,
        email: '',
    };

    prepareDrawer = () => {
        this.setState({
            open: this.props.open,
        });
    };

    prepareAuthState = () => {
        this.setState({
            signedIn: !!this.props.authState.signedIn,
            email: this.props.authState.email,
        });
    };

    onSigninClick = () => {
        AuthService.signIn();
    };

    onSignoutClick = () => {
        AuthService.signOut()
            .then(this.props.onSignout)
            .catch(err => console.error('Error signing out', err));
    };

    componentDidMount = () => {
        this.prepareDrawer();
        this.prepareAuthState();
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.open !== prevProps.open) {
            this.prepareDrawer();
        }
        if (this.props.authState.signedIn !== prevProps.authState.signedIn ||
            this.props.authState.email !== prevProps.authState.email) {
            this.prepareAuthState();
        }
    };

    render() {
        const {classes} = this.props;
        const sideList = (
            <div className={classes.list}>
                <List>
                    <ListItem button disabled={!this.state.signedIn} onClick={this.props.onRefresh}>
                        <ListItemIcon><RefreshIcon/></ListItemIcon>
                        <ListItemText primary="Refresh"/>
                    </ListItem>
                    <List>
                        <ListItem button onClick={this.props.onAbout}>
                            <ListItemIcon><InfoIcon/></ListItemIcon>
                            <ListItemText primary="About"/>
                        </ListItem>
                    </List>
                </List>
                <Divider/>

                {!this.state.signedIn &&
                <ListItem button onClick={this.onSigninClick}>
                    <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                    <ListItemText primary="Sign in"/>
                </ListItem>}

                {this.state.signedIn &&
                <div>
                    <ListItem>
                        <ListItemText
                            primaryTypographyProps={{style: {fontSize: 'small'}}}
                            primary="Signed in"
                            secondaryTypographyProps={{style: {fontSize: 'small'}}}
                            secondary={this.state.email}
                        />
                    </ListItem>
                    <ListItem button onClick={this.onSignoutClick}>
                        <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                        <ListItemText primary="Sign out"/>
                    </ListItem>
                </div>}
            </div>
        );

        return (
            <Drawer open={this.state.open} onClose={this.props.onCloseClick}>
                <div
                    tabIndex={0}
                    role="button"
                    onClick={this.props.onCloseClick}
                    onKeyDown={this.props.onCloseClick}
                >
                    {sideList}
                </div>
            </Drawer>
        );
    }
}

NavDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    authState: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onAbout: PropTypes.func.isRequired,
    onRefresh: PropTypes.func.isRequired,
    onSignout: PropTypes.func.isRequired,
};

export default withStyles(styles)(NavDrawer);
