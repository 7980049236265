import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AddItemDialog from './AddItemDialog';
import ApiService from '../services/ApiService';

const styles = {
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
};

class ButtonAppBar extends React.Component {
    state = {
        showAdd: false,
    };

    onAddClick = () => {
        this.setState({
            showAdd: true,
        });
    };

    onAddSubmit = (title) => {
        this.onAddHide();
        console.log(`Add item: ${title}`);

        const item = {
            title: title,
        };
        const self = this;
        ApiService.request().post('/', item)
            .then(function (response) {
                console.log(`Added item: ${JSON.stringify(response.data)}`);
                self.props.onChange();
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    onAddHide = () => {
        this.setState({
            showAdd: false,
        });
    };

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.props.onMenuClick}>
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            Beanlist
                        </Typography>
                        <Button color="inherit" disabled={!this.props.signedIn} onClick={this.onAddClick}>Add</Button>
                    </Toolbar>
                </AppBar>
                <AddItemDialog
                    open={this.state.showAdd}
                    handleSubmit={this.onAddSubmit}
                    handleCancel={this.onAddHide}
                />
            </div>
        );
    }
}

ButtonAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
    signedIn: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onMenuClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(ButtonAppBar);
