import firebase from 'firebase/app';
import 'firebase/auth';

class AppService {
    initialised = false;
    token = '';

    init = () => {
        console.log('Initialising Firebase app');
        var config = require('./app_config.json');
        firebase.initializeApp(config);
    };

    checkInit = () => {
        if (!this.initialised) {
            this.initialised = true;
            this.init();
        }
    };
}

export default new AppService();
