import React, {Component} from 'react';
import './App.css';
import ButtonAppBar from './views/ButtonAppBar';
import NavDrawer from './views/NavDrawer';
import AboutDialog from './views/AboutDialog';
import EnhancedTable from './views/EnhancedTable';
import ApiService from './services/ApiService';
import AuthService from './services/AuthService';
import Paper from "@material-ui/core/Paper/Paper";
import WelcomeCard from "./views/WelcomeCard";
import LoadingCard from "./views/LoadingCard";

class App extends Component {
    state = {
        fetching: false,
        data: [],
        menuOpen: false,
        aboutOpen: false,
        authStateTransition: true,
        authState: {},
        signedIn: false,
    };

    fetchItems = () => {
        const self = this;
        console.log('Fetching items');

        this.setState({ fetching: true }, () => {
            ApiService.request().get('/')
            .then((response) => {
                self.setState({
                    data: response.data,
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => self.setState({ fetching: false }));
        });
    };

    setDrawVisible = (visible) => {
        this.setState({
            menuOpen: visible,
        });
    };

    setAboutVisible = (visible) => {
        this.setState({
            aboutOpen: visible,
        });
    };

    onRefresh = () => {
        this.setDrawVisible(false);
        this.fetchItems();
    };

    onAbout = () => {
        this.setDrawVisible(false);
        this.setAboutVisible(true);
    };

    componentDidMount() {
        this.checkAuthState();

        const self = this;
        AuthService.registerAuthStateTransitionObserver(transition => {
            self.setState({
                authStateTransition: transition,
            });
        });
    }

    checkAuthState = () => {
        console.log('Checking auth state');
        const self = this;

        AuthService.checkState()
            .then(signedIn => {
                const authState = AuthService.authState();
                self.setState({
                    authState: authState,
                    signedIn: !!authState.signedIn,
                }, () => {
                    if (signedIn) self.fetchItems();
                });
            })
            .catch(err => {
                console.error(err);
                self.setState({
                    authState: {},
                    signedIn: false,
                });
            });
    };

    render() {
        return (
            <div className="App">
                <ButtonAppBar
                    onMenuClick={() => this.setDrawVisible(true)}
                    onChange={this.fetchItems}
                    signedIn={this.state.signedIn}
                />
                <NavDrawer
                    open={this.state.menuOpen}
                    onCloseClick={() => this.setDrawVisible(false)}
                    onRefresh={this.onRefresh}
                    onAbout={this.onAbout}
                    authState={this.state.authState}
                    onSignout={this.checkAuthState}
                />
                <AboutDialog
                    open={this.state.aboutOpen}
                    onCloseClick={() => this.setAboutVisible(false)}
                />

                {!this.state.signedIn &&
                <Paper>
                    {this.state.authStateTransition &&
                    <LoadingCard/>}

                    {!this.state.authStateTransition &&
                    <WelcomeCard/>}
                </Paper>}

                {this.state.signedIn && this.state.fetching &&
                <LoadingCard message="Fetching items..."/>}

                {this.state.signedIn && !this.state.fetching &&
                <EnhancedTable
                    data={this.state.data}
                    onChange={this.fetchItems}
                />}
            </div>
        );
    }
}

export default App;
