import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};

function LoadingCard(props) {
    const {classes, message} = props;

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {message}
                </Typography>
                <Typography variant="h5" component="h2">
                    Getting ready
                </Typography>
                <CircularProgress style={{marginTop: 20}} className={classes.progress}/>
            </CardContent>
        </Card>
    );
}

LoadingCard.propTypes = {
    classes: PropTypes.object.isRequired,
    message: PropTypes.string,
};

LoadingCard.defaultProps = {
    message: 'Hold tight...',
};

export default withStyles(styles)(LoadingCard);
