import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { TextField } from '@material-ui/core';

const styles = {
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class AddItemDialog extends React.Component {
    state = {
        item: '',
    };

    handleCancel = () => {
        this.clearItem();
        this.props.handleCancel();
    };

    handleSubmit = () => {
        const title = this.state.item;
        if (title && title.length > 0) {
            this.clearItem();
            this.props.handleSubmit(title);
        } else {
            this.handleCancel();
        }
    };

    clearItem = () => {
        this.setState({
            item: ''
        });
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog
                    fullScreen
                    open={this.props.open}
                    onClose={this.handleCancel}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton color="inherit" onClick={this.handleCancel} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                Add item
                            </Typography>
                            <Button color="inherit" onClick={this.handleSubmit}>
                                Save
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <List>
                        <ListItem>
                            <TextField
                                autoFocus
                                margin="none"
                                id="name"
                                label="Item description"
                                type="text"
                                fullWidth
                                value={this.state.item}
                                onChange={e => this.setState({ item: e.target.value })}
                            />
                        </ListItem>
                    </List>
                </Dialog>
            </div>
        );
    }
}

AddItemDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddItemDialog);
