import firebase from 'firebase/app';
import 'firebase/auth';
import AppService from "./AppService";

const DEFAULT_AUTH_STATE = {
    signedIn: false,
    token: '',
    email: ''
};

class AuthService {
    _authState = DEFAULT_AUTH_STATE;
    _authStateTransitionObserver = () => {};

    /**
     * @returns {boolean} whether the user is signed in
     */
    signedIn = () => {
        return (null != this._authState.token);
    };

    signIn = () => {
        AppService.checkInit();
        this.setAuthStateTransition();

        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithRedirect(provider);
    };

    checkState = () => new Promise((resolve, reject) => {
        this.setAuthStateTransition();
        AppService.checkInit();
        const self = this;

        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                // User is signed in.
                console.log('User is signed in');
                self.fetchIdToken(self, resolve, reject);

            } else {
                // User is signed out.
                console.log('User is signed out');
                self.clearAuthState();

                console.log('Checking for redirect result');
                firebase.auth().getRedirectResult().then(function (result) {
                    if (result.credential) {
                        self.fetchIdToken(self, resolve, reject);
                    } else {
                        console.log('No credential found');
                        self.clearAuthStateTransition();
                        resolve(false);
                    }

                }).catch(function (error) {
                    console.error(`Failed to get redirect result: ${error.message}`);
                    self.clearAuthStateTransition();
                    reject(error);
                });
            }
        });
    });

    fetchIdToken = (self, resolve, reject) => {
        console.log('Retrieving ID token...');

        const currentUser = firebase.auth().currentUser;
        if (!currentUser) {
            console.log('No current user signed in');
            resolve(false);
            this.clearAuthStateTransition();

        } else {
            currentUser.getIdToken(true)
                .then(idToken => {
                    self._authState = {
                        signedIn: true,
                        token: idToken,
                        email: currentUser.email,
                    };
                    console.log('Retrieved ID token');
                    self.clearAuthStateTransition();
                    resolve(true);
                })
                .catch(function (error) {
                    console.error(error);
                    self.clearAuthStateTransition();
                    reject(error);
                });
        }
    };

    /**
     * @param callback {function}
     */
    registerAuthStateTransitionObserver(callback) {
        this._authStateTransitionObserver = callback;

        // restore from session state
        if (sessionStorage.getItem('authStateTransition') === 'true') {
            this.setAuthStateTransition();
        }
    }

    setAuthStateTransition() {
        sessionStorage.setItem('authStateTransition', 'true');
        if (this._authStateTransitionObserver) {
            this._authStateTransitionObserver(true);
        }
    }

    clearAuthStateTransition() {
        sessionStorage.removeItem('authStateTransition');
        if (this._authStateTransitionObserver) {
            this._authStateTransitionObserver(false);
        }
    }

    signOut = () => {
        const self = this;
        return firebase.auth().signOut()
            .then(result => {
                self.clearAuthState();
                return result;
            });
    };

    authState = () => this._authState;

    clearAuthState = () => {
        this.clearAuthStateTransition();
        return this._authState = DEFAULT_AUTH_STATE;
    };
}

export default new AuthService();
